<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco Copywriters</h4>
                </div>
                <DataTable :value='rows' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters' filterDisplay='menu' :loading='loading'
                           responsiveLayout='scroll'
                           >
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            v-model="filters['global'].value"
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='slug' header='Slug' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.slug }}
                        </template>
                    </Column>
                    <Column field='name' header='Nome' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.name }}
                        </template>
                    </Column>
                    <Column field='id' header='Azioni'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='mr-2 mb-2 p-button-info' title='Modifica'
                                    @click='$router.push({ path: gotoId(slotProps.data.id) })'></Button>
                        </template>
                    </Column>


                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Cont/Copywriters');
import Notifications from '../../../../mixins/notifications';
import Navigations from '../../../../mixins/navigations';
import ListHeader from '../../../../components/4books/ListHeader';
export default {
    components: {
        ListHeader
    },
    data() {
        return {
            filters: null,
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList']),
        refreshList() {
            this.fetchList().then().catch(err => {
                this.ShowStandardError(err.response);
            });
        }
    },
    mixins: [
        Notifications,
        Navigations,
    ],
};
</script>
